import { auth } from './utils/firebase'
import Login from './components/Login/Login'
import { useAuthState } from 'react-firebase-hooks/auth'
import useStyles from './App.styles'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import PageWrapper from './components/PageWrapper/PageWrapper'
import ChatBox from './components/ChatBox/ChatBox'
import ChatForm from './components/ChatForm/ChatForm'
import MessageContextProvider from './context/MessagesContext'
import { useWindowWidth } from '@react-hook/window-size'
import { MOBILE_MIN_WIDTH } from './utils/styles'
import Online from './components/Online/Online'

const App: React.FC = () => {
	const classes = useStyles()
	const [user] = useAuthState(auth)
	const width = useWindowWidth()

	if (!user) {
		return (
			<PageWrapper>
				<Login />
			</PageWrapper>
		)
	}

	return (
		<PageWrapper>
			<Header />
			<main className={classes.content}>
				<MessageContextProvider>
					<p className={classes.hero}>
						<h1 className={width > MOBILE_MIN_WIDTH ? classes.large : ''}>
							Merry Christmas {user?.displayName}!
						</h1>
						{width > MOBILE_MIN_WIDTH && <Online />}
					</p>
					<ChatBox />
					<ChatForm />
				</MessageContextProvider>
			</main>
			<Footer />
		</PageWrapper>
	)
}

export default App
