import { createUseStyles } from 'react-jss'
import { BORDER_RADIUS_HALF, LINEAR_GRADIENT } from '../../utils/styles'

const styles = {
	box: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		width: '100%',
		backgroundImage: LINEAR_GRADIENT,
		margin: '1em 0',
		gap: '1em',
		padding: '1em',
		borderRadius: BORDER_RADIUS_HALF,
	},
	text: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		textAlign: 'initial',
		gap: '1em',
	},
	image: {},
	name: {
		fontWeight: 'bold',
	},
	content: {
		fontSize: '1.2em',
		whiteSpace: 'pre-wrap',
	},
}

export default createUseStyles(styles)
