import { createUseStyles } from 'react-jss'
import { ACCENT, BORDER_RADIUS_HALF, SECONDARY, TEXT } from '../../utils/styles'

const styles = {
	button: {
		background: SECONDARY,
		color: ACCENT,
		borderColor: TEXT,
		borderRadius: BORDER_RADIUS_HALF,
		padding: '1em 1.5em',
		fontSize: '1em',
		fontWeight: 'bold',
		cursor: 'pointer',
	},
}

export default createUseStyles(styles)
