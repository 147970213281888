import { Who } from '../../types'
import { logout } from '../../utils/firebase'
import { useWindowWidth } from '@react-hook/window-size'
import Button from '../Button/Button'
import ProfilePic from '../ProfilePic/ProfilePic'
import useStyles from './Header.styles'
import { MOBILE_MIN_WIDTH } from '../../utils/styles'

const Component: React.FC = () => {
	const classes = useStyles()
	const width = useWindowWidth()

	return (
		<div className={classes.header}>
			<div className={classes.section}>
				<ProfilePic who={Who.Santa} />
				{width > MOBILE_MIN_WIDTH && <h3>Santa Chat</h3>}
			</div>
			<div className={classes.section}>
				{width > MOBILE_MIN_WIDTH && <ProfilePic who={Who.User} />}
				<Button onClick={logout}>Sign out</Button>
			</div>
		</div>
	)
}

export default Component
