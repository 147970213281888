import { createUseStyles } from 'react-jss'
import { ACCENT_ALT, BACKGROUND, TEXT } from '../../utils/styles'

const styles = {
	page: {
		backgroundImage: `url(${process.env.PUBLIC_URL}/img/tile.png)`,
		backgroundRepeat: 'repeat',
		backgroundColor: BACKGROUND,
		color: TEXT,
		minHeight: '100vh',
		maxHeight: '100vh',
		minWidth: '100vw',
		display: 'grid',
		gridTemplateRows: 'auto 1fr auto',
		overflowX: 'hidden',
		'& a': {
			color: ACCENT_ALT,
			textDecoration: 'none',
			fontWeight: 'bold',
		},
	},
}

export default createUseStyles(styles)
