import { useAuthState } from 'react-firebase-hooks/auth'
import { Message, Who } from '../../types'
import { auth } from '../../utils/firebase'
import ProfilePic from '../ProfilePic/ProfilePic'
import useStyles from './ChatEntry.styles'

const ChatEntry: React.FC<Message> = ({ who, content }: Message) => {
	const classes = useStyles()
	const [user] = useAuthState(auth)

	const name =
		who === Who.User && user?.displayName ? user.displayName : Who[who]

	return (
		<div className={classes.box}>
			<div className={classes.image}>
				<ProfilePic who={who} />
			</div>
			<div className={classes.text}>
				<span className={classes.name}>{name}</span>
				<span className={classes.content}>{content}</span>
			</div>
		</div>
	)
}

export default ChatEntry
