import { useContext, useEffect, useRef } from 'react'
import { MessagesContext } from '../../context/MessagesContext'
import ChatEntry from '../ChatEntry/ChatEntry'
import useStyles from './ChatBox.styles'

const ChatBox: React.FC = () => {
	const classes = useStyles()
	const { messages } = useContext(MessagesContext)
	const listRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (listRef.current) {
			listRef.current.scrollTop =
				listRef.current.scrollHeight - listRef.current.offsetHeight
		}
	}, [messages])

	return (
		<div className={classes.container} ref={listRef}>
			{messages?.map((message) => (
				<ChatEntry {...message} key={message.content} />
			))}
		</div>
	)
}

export default ChatBox
