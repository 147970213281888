export const BACKGROUND = '#EDF4FA'
export const BACKGROUND_ALPHA = '#EDF4FACC'
export const PRIMARY = '#90aab9'
export const SECONDARY = '#d7e0d6'
export const ACCENT = '#c40513'
export const ACCENT_ALT = '#3C6012'
export const TEXT = '#180617'

export const BORDER_RADIUS = '2em'
export const BORDER_RADIUS_HALF = '1em'

export const LINEAR_GRADIENT =
	'linear-gradient(rgba(255, 255, 255, 255), rgba(255, 255, 255, 0))'

export const MOBILE_MIN_WIDTH = 768
