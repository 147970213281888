import { createUseStyles } from 'react-jss'

const styles = {
	container: {
		flexDirection: 'column',
		justifyContent: 'flex-end',
		height: '100%',
		maxHeight: '100%',
		width: '100%',
		overflowY: 'scroll',
	},
}

export default createUseStyles(styles)
