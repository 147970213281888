import { Who } from '../../types'
import { signIn } from '../../utils/firebase'
import Button from '../Button/Button'
import PageWrapper from '../PageWrapper/PageWrapper'
import ProfilePic from '../ProfilePic/ProfilePic'
import useStyles from './Login.styles'

const Login: React.FC = () => {
	const classes = useStyles()
	return (
		<PageWrapper>
			<div className={classes.page}>
				<div className={classes.panel}>
					<ProfilePic who={Who.Santa} />
					<h1>Chat with Santa!</h1>
					<p className={classes.large}>
						Hi there! I&apos;m Santa, and I&apos;m here to spread holiday cheer
						and bring joy to people all around the world. As you may know,
						I&apos;m a very busy guy during the holiday season, but I always
						make time to chat with good boys and girls.
					</p>
					<p className={classes.large}>
						That&apos;s why I&apos;m excited to introduce my new website where
						you can chat with me anytime, day or night! No matter where you are,
						you can log on and have a conversation with me in real time.
					</p>
					<p className={classes.large}>
						Whether you want to ask me about my favorite cookies, tell me about
						your holiday wish list, or just say hello, I&apos;m always happy to
						chat. I love hearing from children (and adults!) and I&apos;m always
						here to lend a listening ear.
					</p>
					<p className={classes.large}>
						So don&apos;t be shy, come visit my website and say hello! I
						can&apos;t wait to chat with you. Ho ho ho!
					</p>
					<b>A note for parents</b>
					<p>
						Please be aware that our chat service with Santa Claus is powered by
						a chatbot, which means that the responses and content provided
						during your conversation are not coming directly from a human being.
						While we strive to create a fun and enjoyable experience for all
						users, it is important to keep in mind that the chatbot&apos;s
						responses may not always be accurate or appropriate.
					</p>
					<p>
						We do not capture or store any data from your conversation, and we
						cannot control the content of conversations. It is important to
						supervise your child&apos;s use of this service and remind them to
						be safe and respectful when chatting online.
					</p>
					<p>
						This service is provided free of charge. If you like it, please
						consider{' '}
						<a href="https://www.buymeacoffee.com/MilkyTaste/santa-chat">
							donating to the developer
						</a>
						.
					</p>
					<Button onClick={signIn}>Sign in</Button>
				</div>
			</div>
		</PageWrapper>
	)
}

export default Login
