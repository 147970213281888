import { Who } from '../../types'
import { auth } from '../../utils/firebase'
import useStyles from './ProfilePic.styles'

interface Props {
	who: Who
}

const ProfilePic: React.FC<Props> = ({ who }: Props) => {
	const classes = useStyles()
	let src = `${process.env.PUBLIC_URL}/img/santa.png`
	if (who === Who.User) {
		src = auth.currentUser?.photoURL || src
	}

	return <img className={classes.pic} alt={`${who} PFP`} src={src} />
}

export default ProfilePic
