import { createUseStyles } from 'react-jss'
import {
	ACCENT_ALT,
	BORDER_RADIUS_HALF,
	SECONDARY,
	TEXT,
} from '../../utils/styles'

const styles = {
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		alignItems: 'center',
		width: '100%',
		gap: '1em',
		margin: '1em 0',
	},
	input: {
		background: SECONDARY,
		color: ACCENT_ALT,
		borderColor: TEXT,
		borderRadius: BORDER_RADIUS_HALF,
		padding: '1em 1.5em',
		fontSize: '1em',
		fontWeight: 'bold',
		minWidth: 0,
	},
}

export default createUseStyles(styles)
