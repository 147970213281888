import { createUseStyles } from 'react-jss'
import { BACKGROUND_ALPHA, TEXT } from '../../utils/styles'

const styles = {
	page: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: TEXT,
		height: '100vh',
		width: '100vw',
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1.5em',
		padding: '2em',
		width: '100%',
		maxWidth: 'min(99vw, 1080px)',
		maxHeight: '100%',
		overflowY: 'auto',
		margin: 'auto',
		backgroundColor: BACKGROUND_ALPHA,
		'& > *': {
			margin: '0 auto',
		},
	},
	large: {
		fontSize: '1.2em',
	},
}

export default createUseStyles(styles)
