import { createContext, useState } from 'react'
import { Message, Who } from '../types'

interface CtxProps {
	messages?: Message[]
	addMessage?: (who: Who, content: string) => void
}

interface Props {
	children: React.ReactNode | React.ReactNode[]
}

export const MessagesContext = createContext<CtxProps>({})

const MessageContextProvider: React.FC<Props> = ({ children }: Props) => {
	const [messages, setMessages] = useState<Message[]>([
		{
			who: Who.Santa,
			content: 'Ho Ho Ho! Merry Christmas!',
		},
	])

	const addMessage = (who: Who, content: string) => {
		setMessages((msgs) => [...msgs, { who, content }])
	}

	return (
		<MessagesContext.Provider value={{ messages, addMessage }}>
			{children}
		</MessagesContext.Provider>
	)
}

export default MessageContextProvider
