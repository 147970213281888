import { createUseStyles } from 'react-jss'
import { LINEAR_GRADIENT } from '../../utils/styles'

const styles = {
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '1em',
		width: '100vw',
		backgroundImage: LINEAR_GRADIENT,
	},
	section: {
		display: 'flex',
		alignItems: 'center',
		gap: '1em',
	},
}

export default createUseStyles(styles)
