import { createUseStyles } from 'react-jss'
import { BACKGROUND, TEXT } from '../../utils/styles'

const styles = {
	footer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: TEXT,
		textAlign: 'center',
		backgroundColor: `${BACKGROUND}BB`,
		marginTop: '1em',
		padding: '0.5em 0',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '1em',
		width: '100%',
		marginTop: '1em',
	},
}

export default createUseStyles(styles)
