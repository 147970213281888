import { initializeApp } from 'firebase/app'
import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	signOut,
} from 'firebase/auth'

const firebaseConfig = {
	apiKey: 'AIzaSyCIbHBRJOfA4OvOmFbzSVBRvJlmE9TnNng',
	authDomain: 'santachat-e3ab4.firebaseapp.com',
	projectId: 'santachat-e3ab4',
	storageBucket: 'santachat-e3ab4.appspot.com',
	messagingSenderId: '392403775264',
	appId: '1:392403775264:web:028eb98814ddefc4fef145',
}

export const app = initializeApp(firebaseConfig)

const provider = new GoogleAuthProvider()
export const auth = getAuth(app)

export const signIn = async () => {
	await signInWithPopup(auth, provider)
}

export const logout = async () => {
	await signOut(auth)
}
