import { createUseStyles } from 'react-jss'
import { BACKGROUND_ALPHA, BORDER_RADIUS } from './utils/styles'

const styles = {
	content: {
		textAlign: 'center',
		display: 'grid',
		gridTemplateRows: 'auto 1fr auto',
		alignItems: 'center',
		backgroundColor: BACKGROUND_ALPHA,
		width: '100%',
		padding: '1em',
		maxWidth: 'min(99vw, 1080px)',
		minHeight: '100%',
		maxHeight: '100%',
		margin: 'auto',
		borderRadius: BORDER_RADIUS,
	},
	hero: {
		'& > h1': {
			fontSize: '1.5em',
			margin: 0,
		},
		fontWeight: 'bold',
	},
	large: {
		fontSize: '2em',
	},
}

export default createUseStyles(styles)
