import { createUseStyles } from 'react-jss'

const styles = {
	pic: {
		borderRadius: '50%',
		width: '5em',
	},
}

export default createUseStyles(styles)
