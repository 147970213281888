import { createUseStyles } from 'react-jss'
import { ACCENT_ALT, BACKGROUND_ALPHA } from '../../utils/styles'

const styles = {
	indicator: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '0.5em',
	},
	dot: {
		width: '20px',
		height: '20px',
		borderRadius: '50%',
		background: ACCENT_ALT,
		boxShadow: `0 0 10px 3px ${BACKGROUND_ALPHA}`,
	},
}

export default createUseStyles(styles)
