import useStyles from './PageWrapper.styles'
import { ReactNode } from 'react'
import Snowfall from 'react-snowfall'
import { BACKGROUND_ALPHA, MOBILE_MIN_WIDTH } from '../../utils/styles'
import { useWindowWidth } from '@react-hook/window-size'

interface Props {
	children?: ReactNode | ReactNode[]
}

const PageWrapper: React.FC<Props> = ({ children }: Props) => {
	const classes = useStyles()
	const width = useWindowWidth()

	return (
		<>
			<Snowfall
				color={BACKGROUND_ALPHA}
				snowflakeCount={width > MOBILE_MIN_WIDTH ? 150 : 75}
			/>
			<div className={classes.page}>{children}</div>
		</>
	)
}

export default PageWrapper
