import useStyles from './Online.styles'

const Online: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.indicator}>
			<div className={classes.dot}></div>
			<span>Santa is online and ready to chat.</span>
		</div>
	)
}

export default Online
