import { useContext, useRef, useState } from 'react'
import { MessagesContext } from '../../context/MessagesContext'
import { Who } from '../../types'
import { auth } from '../../utils/firebase'
import { API_URL } from '../../utils/santaConfig'
import Button from '../Button/Button'
import useStyles from './ChatForm.styles'

interface LastMessages {
	user: string
	santa: string
}

const ChatForm: React.FC = () => {
	const [msg, setMsg] = useState('')
	const [last, setLast] = useState<LastMessages>()
	const classes = useStyles()
	const { addMessage } = useContext(MessagesContext)
	const inputRef = useRef<HTMLInputElement>(null)

	if (!addMessage) {
		return null
	}

	const sendMessage = async () => {
		if (!auth.currentUser || !msg) {
			return
		}

		const user = msg
		addMessage(Who.User, user)
		inputRef.current && (inputRef.current.value = '')

		const token = await auth.currentUser.getIdToken()
		const body = {
			value: user,
			lastValue: last?.user,
			lastResponse: last?.santa,
		}
		const response = await fetch(`${API_URL}/chat`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		})
		const data = await response.json()
		console.log(data)
		const santa = data.response.trim()
		addMessage(Who.Santa, santa)

		setLast({ user, santa })
	}

	return (
		<form className={classes.container} onSubmit={(e) => e.preventDefault()}>
			<input
				ref={inputRef}
				className={classes.input}
				onChange={(e) => setMsg(e.target.value)}
			/>
			<Button onClick={sendMessage}>Send</Button>
		</form>
	)
}

export default ChatForm
