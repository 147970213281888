import useStyles from './Button.styles'

interface Props {
	onClick: React.MouseEventHandler<HTMLButtonElement>
	children: React.ReactNode | React.ReactNode[]
}

const Button: React.FC<Props> = ({ onClick, children }: Props) => {
	const classes = useStyles()

	return (
		<button className={classes.button} onClick={onClick}>
			{children}
		</button>
	)
}

export default Button
