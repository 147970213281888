import useStyles from './Footer.styles'

const Footer: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.footer}>
			<div className={classes.row}>
				<span>
					Like it?{' '}
					<a href="https://www.buymeacoffee.com/MilkyTaste/santa-chat">
						Please donate!
					</a>
				</span>
				<span>
					&copy; 2022 Michael Standen via{' '}
					<a href="https://aocollab.tech">Ao Collaboration Ltd</a>
				</span>
				<span>
					Powered by <a href="https://openai.com/">OpenAI</a>
				</span>
			</div>
		</div>
	)
}

export default Footer
